import {
  required,
  maxLength,
} from '@vuelidate/validators';

export const addressRules = {
  street: {
    required,
    maxLength: maxLength(100),
  },
  num: {
    required,
    maxLength: maxLength(15),
  },
  numint: {
    maxLength: maxLength(15),
  },
  district: {
    required,
    maxLength: maxLength(40),
  },
  city: {
    required,
    maxLength: maxLength(40),
  },
  state: {
    required,
    maxLength: maxLength(40),
  },
  cp: {
    required,
    maxLength: maxLength(10),
  },
  isFiscalResidence: {
    required,
  },
};

export const investmentRules = {
  identificationType: { required },
  folio: { required },
  issuingAuthority: { required },
  photoFrontSide: { required },
  photoBackSide: { required },
  fiscalId: { required },
  rfcNumber: { required },
};
