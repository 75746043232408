<template>
  <select :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
    <option :value="null">0</option>
    <option :value="1">$1.00</option>
    <option :value="9000">$9,000.00</option>
    <option :value="12000">$12,000.00</option>
    <option :value="15000">$15,000.00</option>
    <option :value="18000">$18,000.00</option>
    <option :value="21000">$21,000.00</option>
    <option :value="24000">$24,000.00</option>
    <option :value="27000">$27,000.00</option>
    <option :value="30000">$30,000.00</option>
  </select>
</template>

<script>
export default {
  name: 'CurrencyInput',
  props: ['modelValue'],
  emits: ['update:modelValue'],
};
</script>
