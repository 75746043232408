import { defineStore } from 'pinia';
// import useAuthStore from './authStore';
import UserService from '../services/UserService';

// const authStore = useAuthStore();
const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    status: 'Start',
    loading: false,
    newInvestment: null,
  }),
  actions: {
    async updateUser(data) {
      this.loading = true;
      try {
        this.newInvestment = await UserService.update(data);
        this.status = 'Finish';
        this.loading = false;
      } catch (error) {
        this.status = 'Error';
        this.loading = false;
        alert(error);
      }
    },
  },
});

export default useUserStore;
