<script>
/* eslint-disable no-undef */

import numeral from 'numeral';
import {
  ref,
  watch,
  reactive,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from '@vuelidate/validators';
import InversionPicker from '../components/InversionPicker.vue';
import { APP_OP_MEN, APP_OP_KEY, ENV } from '../config';
import useUserStore from '../stores/userStore';
import useAuthStore from '../stores/authStore';
import { investmentRules, addressRules } from '../helpers/formRules';

export default {
  name: 'Form',
  components: {
    InversionPicker,
  },
  mounted() {
    OpenPay.setId(APP_OP_MEN);
    OpenPay.setApiKey(APP_OP_KEY);
    OpenPay.setSandboxMode(ENV !== 'production');
    this.deviceSessionId = OpenPay.deviceData.setup();
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();

    const loading = false;
    const total = 18322000;
    const terms = ref(true);
    const confirmed = ref(true);
    const tabSelected = ref('datos');
    const deviceSessionId = '';
    const count = ref(false);
    const errors = ref([]);
    const newInvestment = ref({});
    const fiscalResidence = {
      street: authStore.user?.street || '',
      num: authStore.user?.num || '',
      numint: authStore.user?.numint || '',
      district: authStore.user?.district || '',
      city: authStore.user?.city || '',
      state: authStore.user?.state || '',
      cp: authStore.user?.cp || '',
      isFiscalResidence: true,
    };

    const fresidence = reactive(fiscalResidence);
    const fr$ = useVuelidate(addressRules, fresidence);
    fr$.value.$touch();

    const addressFields = {
      street: authStore.user?.street || '',
      num: authStore.user?.num || '',
      numint: authStore.user?.numint || '',
      district: authStore.user?.district || '',
      city: authStore.user?.city || '',
      state: authStore.user?.state || '',
      cp: authStore.user?.cp || '',
      isFiscalResidence: false,
    };

    const profileFields = {
      alias: authStore.user?.alias || '',
      curp: authStore.user?.curp || '',
      borndate: authStore.user?.borndate || '',
      phone: authStore.user?.phone || '',
      gender: authStore.user?.gender || 'M',
      immigrationStatus: authStore.user?.immigrationStatus || 'NACIONAL',
      amount: 1,
      partialAmount: '$1',
      percentage: 0.0,
      payments: null,
      nationality: 'MX',
      countryOfOrigin: 'MX',
      taxCountry: 'MX',
      address: {
        ...addressFields,
      },
      proofOfAddress: null,
      proofOfAddress1: null,
    };

    const profile = reactive(profileFields);
    const profileRules = {
      borndate: { required },
      alias: { required },
      gender: { required },
      countryOfOrigin: { required },
      taxCountry: { required },
      immigrationStatus: { required },
      curp: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18),
      },
      address: {
        ...addressRules,
      },
      nationality: { required },
      proofOfAddress: {
        required: requiredIf(() => profile.address.isFiscalResidence),
      },
      proofOfAddress1: {
        required: requiredIf(() => !profile.address.isFiscalResidence),
      },
    };

    const v$ = useVuelidate(profileRules, profile);
    v$.value.$touch();

    const investmentFields = {
      identificationType: 'ine',
      folio: '',
      issuingAuthority: '',
      photoFrontSide: null,
      photoBackSide: null,
      fiscalId: '',
      rfcNumber: null,
    };

    const investmentData = reactive(investmentFields);
    const v2$ = useVuelidate(investmentRules, investmentData);
    v2$.value.$touch();

    const card = ref({
      name: authStore.user?.fullname || '',
      number: '',
      month: '',
      year: null,
      cvv: null,
    });

    function setPartialAmount(amount, value) {
      let partialAmount = amount;
      if (value) {
        const { payments } = value;
        partialAmount = Math.ceil(amount / payments);
      }
      profile.partialAmount = numeral(partialAmount).format('$0,0');
    }

    watch(
      () => profile.amount,
      (value) => {
        profile.percentage = ((value * 100) / total).toFixed(4);
        setPartialAmount(value, profile.payments);
      },
    );

    watch(
      () => profile.payments,
      (value) => {
        setPartialAmount(profile.amount, value);
      },
    );

    return {
      loading,
      profile,
      fresidence,
      investmentData,
      v$,
      v2$,
      fr$,
      deviceSessionId,
      count,
      tabSelected,
      errors,
      newInvestment,
      card,
      authStore,
      userStore,
      terms,
      confirmed,
    };
  },
  methods: {
    createToken() {
      const {
        name,
        number,
        cvv,
        month,
        year,
      } = this.card;

      return new Promise((resolve, reject) => {
        OpenPay.token.create({
          holder_name: name,
          card_number: number,
          cvv2: cvv,
          expiration_month: month,
          expiration_year: year,
        }, resolve, reject);
      });
    },
    async suscribe() {
      this.loading = true;
      if (!this.deviceSessionId) {
        this.errors = ['Sesion del dispositivo es requerido'];
        this.loading = false;
        return;
      }

      if (!this.card.number && !this.card.cvv) {
        this.errors = ['Completa todos los datos'];
        this.loading = false;
        return;
      }

      let content2 = 1;
      if (this.profile.payments) {
        content2 = this.profile.payments.payments;
      }
      if (
        window.confirm(`¿Seguro que desea invertir ${this.profile.amount} pesos
        entre ${content2} meses por el ${this.profile.percentage} % de DocInWay?`)
      ) {
        try {
          // eslint-disable-next-line
          const profile = this.profile;
          // eslint-disable-next-line
          const investmentData = this.investmentData;

          const { data } = await this.createToken();
          const formData = new FormData();
          const dataFetch = {
            ...profile,
            ...investmentData,
            token: data.id,
            deviceId: this.deviceSessionId,
          };
          Object.keys(dataFetch).forEach((key) => formData.append(key, dataFetch[key]));
          formData.delete('address');
          formData.append('address', JSON.stringify(dataFetch.address));
          formData.append('fresidence', JSON.stringify(this.fresidence));

          await this.userStore.updateUser(formData);
          if (this.userStore.status === 'Finish') {
            this.newInvestment = this.userStore.newInvestment;
            this.tabSelected = 'certificado';
          }
        } catch (err) {
          console.log(err);
          if (err.data) {
            this.errors = [err.data.description];
          } else {
            this.errors = [err.message];
          }
        }
      }
      this.loading = false;
    },
    proofOfAddressChange(e) {
      const selectedFile = e.target.files[0];
      this.profile.proofOfAddress = selectedFile;
    },
    proofOfAddressChange2(e) {
      const selectedFile = e.target.files[0];
      this.profile.proofOfAddress1 = selectedFile;
    },
    photoFrontSideChange(e) {
      const selectedFile = e.target.files[0];
      this.investmentData.photoFrontSide = selectedFile;
    },
    photoBackSideChange(e) {
      const selectedFile = e.target.files[0];
      this.investmentData.photoBackSide = selectedFile;
    },
    rfcNumberChange(e) {
      const selectedFile = e.target.files[0];
      this.investmentData.rfcNumber = selectedFile;
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0];
      this.selectedFile = selectedFile;
    },
  },
};
</script>

<template>
  <div class="form-title">
    <h2>¡Ya casi estás listo para invertir en Doc in Way!</h2>
    <div class="form-title__steps">
      <div class="form-title__steps__step" v-bind:class="{ 'form-title__steps__step-active': tabSelected === 'datos' }">
        <div class="form-title__steps__step__circle">1</div>
        Datos de Inversionista
      </div>
      <div class="form-title__steps__step"
        v-bind:class="{ 'form-title__steps__step-active': tabSelected === 'inversion' }">
        <div class="form-title__steps__step__circle">2</div>
        Datos de Inversión
      </div>
      <div class="form-title__steps__step"
        v-bind:class="{ 'form-title__steps__step-active': tabSelected === 'certificado' }">
        <div class="form-title__steps__step__circle">3</div>
        Obtención de Certificado
      </div>
    </div>
  </div>

  <div class="form" v-if="tabSelected === 'datos' && !loading">
    <div class="form__content">
      <div class="form__content__label">
        <label>Alias</label>
        <input type="text" v-model="v$.alias.$model" />
        <span class="form__content__label-errors" v-if="v$.alias.$error">
          {{ v$.alias.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>CURP</label>
        <input type="text" v-model="v$.curp.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.curp.$error">
          {{ v$.curp.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <hr>
      <div class="form__content__label">
        <label>Sexo</label>
        <select v-model="v$.gender.$model">
          <option value="M">Masculino</option>
          <option value="F">Femenino</option>
        </select>
        <span class="form__content__label-errors" v-if="v$.gender.$error">
          {{ v$.gender.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Fecha de Nacimiento</label>
        <input type="date" v-model="v$.borndate.$model" />
        <span class="form__content__label-errors" v-if="v$.borndate.$error">
          {{ v$.borndate.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>País de Origen</label>
        <select v-model="v$.countryOfOrigin.$model">
          <option value="AF">Afganistán</option>
          <option value="AL">Albania</option>
          <option value="DE">Alemania</option>
          <option value="AD">Andorra</option>
          <option value="AO">Angola</option>
          <option value="AI">Anguilla</option>
          <option value="AQ">Antártida</option>
          <option value="AG">Antigua y Barbuda</option>
          <option value="AN">Antillas Holandesas</option>
          <option value="SA">Arabia Saudí</option>
          <option value="DZ">Argelia</option>
          <option value="AR">Argentina</option>
          <option value="AM">Armenia</option>
          <option value="AW">Aruba</option>
          <option value="AU">Australia</option>
          <option value="AT">Austria</option>
          <option value="AZ">Azerbaiyán</option>
          <option value="BS">Bahamas</option>
          <option value="BH">Bahrein</option>
          <option value="BD">Bangladesh</option>
          <option value="BB">Barbados</option>
          <option value="BE">Bélgica</option>
          <option value="BZ">Belice</option>
          <option value="BJ">Benin</option>
          <option value="BM">Bermudas</option>
          <option value="BY">Bielorrusia</option>
          <option value="MM">Birmania</option>
          <option value="BO">Bolivia</option>
          <option value="BA">Bosnia y Herzegovina</option>
          <option value="BW">Botswana</option>
          <option value="BR">Brasil</option>
          <option value="BN">Brunei</option>
          <option value="BG">Bulgaria</option>
          <option value="BF">Burkina Faso</option>
          <option value="BI">Burundi</option>
          <option value="BT">Bután</option>
          <option value="CV">Cabo Verde</option>
          <option value="KH">Camboya</option>
          <option value="CM">Camerún</option>
          <option value="CA">Canadá</option>
          <option value="TD">Chad</option>
          <option value="CL">Chile</option>
          <option value="CN">China</option>
          <option value="CY">Chipre</option>
          <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
          <option value="CO">Colombia</option>
          <option value="KM">Comores</option>
          <option value="CG">Congo</option>
          <option value="CD">Congo, República Democrática del</option>
          <option value="KR">Corea</option>
          <option value="KP">Corea del Norte</option>
          <option value="CI">Costa de Marfíl</option>
          <option value="CR">Costa Rica</option>
          <option value="HR">Croacia (Hrvatska)</option>
          <option value="CU">Cuba</option>
          <option value="DK">Dinamarca</option>
          <option value="DJ">Djibouti</option>
          <option value="DM">Dominica</option>
          <option value="EC">Ecuador</option>
          <option value="EG">Egipto</option>
          <option value="SV">El Salvador</option>
          <option value="AE">Emiratos Árabes Unidos</option>
          <option value="ER">Eritrea</option>
          <option value="SI">Eslovenia</option>
          <option value="ES">España</option>
          <option value="US">Estados Unidos</option>
          <option value="EE">Estonia</option>
          <option value="ET">Etiopía</option>
          <option value="FJ">Fiji</option>
          <option value="PH">Filipinas</option>
          <option value="FI">Finlandia</option>
          <option value="FR">Francia</option>
          <option value="GA">Gabón</option>
          <option value="GM">Gambia</option>
          <option value="GE">Georgia</option>
          <option value="GH">Ghana</option>
          <option value="GI">Gibraltar</option>
          <option value="GD">Granada</option>
          <option value="GR">Grecia</option>
          <option value="GL">Groenlandia</option>
          <option value="GP">Guadalupe</option>
          <option value="GU">Guam</option>
          <option value="GT">Guatemala</option>
          <option value="GY">Guayana</option>
          <option value="GF">Guayana Francesa</option>
          <option value="GN">Guinea</option>
          <option value="GQ">Guinea Ecuatorial</option>
          <option value="GW">Guinea-Bissau</option>
          <option value="HT">Haití</option>
          <option value="HN">Honduras</option>
          <option value="HU">Hungría</option>
          <option value="IN">India</option>
          <option value="ID">Indonesia</option>
          <option value="IQ">Irak</option>
          <option value="IR">Irán</option>
          <option value="IE">Irlanda</option>
          <option value="BV">Isla Bouvet</option>
          <option value="CX">Isla de Christmas</option>
          <option value="IS">Islandia</option>
          <option value="KY">Islas Caimán</option>
          <option value="CK">Islas Cook</option>
          <option value="CC">Islas de Cocos o Keeling</option>
          <option value="FO">Islas Faroe</option>
          <option value="HM">Islas Heard y McDonald</option>
          <option value="FK">Islas Malvinas</option>
          <option value="MP">Islas Marianas del Norte</option>
          <option value="MH">Islas Marshall</option>
          <option value="UM">Islas menores de Estados Unidos</option>
          <option value="PW">Islas Palau</option>
          <option value="SB">Islas Salomón</option>
          <option value="SJ">Islas Svalbard y Jan Mayen</option>
          <option value="TK">Islas Tokelau</option>
          <option value="TC">Islas Turks y Caicos</option>
          <option value="VI">Islas Vírgenes (EEUU)</option>
          <option value="VG">Islas Vírgenes (Reino Unido)</option>
          <option value="WF">Islas Wallis y Futuna</option>
          <option value="IL">Israel</option>
          <option value="IT">Italia</option>
          <option value="JM">Jamaica</option>
          <option value="JP">Japón</option>
          <option value="JO">Jordania</option>
          <option value="KZ">Kazajistán</option>
          <option value="KE">Kenia</option>
          <option value="KG">Kirguizistán</option>
          <option value="KI">Kiribati</option>
          <option value="KW">Kuwait</option>
          <option value="LA">Laos</option>
          <option value="LS">Lesotho</option>
          <option value="LV">Letonia</option>
          <option value="LB">Líbano</option>
          <option value="LR">Liberia</option>
          <option value="LY">Libia</option>
          <option value="LI">Liechtenstein</option>
          <option value="LT">Lituania</option>
          <option value="LU">Luxemburgo</option>
          <option value="MK">Macedonia, Ex-República Yugoslava de</option>
          <option value="MG">Madagascar</option>
          <option value="MY">Malasia</option>
          <option value="MW">Malawi</option>
          <option value="MV">Maldivas</option>
          <option value="ML">Malí</option>
          <option value="MT">Malta</option>
          <option value="MA">Marruecos</option>
          <option value="MQ">Martinica</option>
          <option value="MU">Mauricio</option>
          <option value="MR">Mauritania</option>
          <option value="YT">Mayotte</option>
          <option value="MX" selected>México</option>
          <option value="FM">Micronesia</option>
          <option value="MD">Moldavia</option>
          <option value="MC">Mónaco</option>
          <option value="MN">Mongolia</option>
          <option value="MS">Montserrat</option>
          <option value="MZ">Mozambique</option>
          <option value="NA">Namibia</option>
          <option value="NR">Nauru</option>
          <option value="NP">Nepal</option>
          <option value="NI">Nicaragua</option>
          <option value="NE">Níger</option>
          <option value="NG">Nigeria</option>
          <option value="NU">Niue</option>
          <option value="NF">Norfolk</option>
          <option value="NO">Noruega</option>
          <option value="NC">Nueva Caledonia</option>
          <option value="NZ">Nueva Zelanda</option>
          <option value="OM">Omán</option>
          <option value="NL">Países Bajos</option>
          <option value="PA">Panamá</option>
          <option value="PG">Papúa Nueva Guinea</option>
          <option value="PK">Paquistán</option>
          <option value="PY">Paraguay</option>
          <option value="PE">Perú</option>
          <option value="PN">Pitcairn</option>
          <option value="PF">Polinesia Francesa</option>
          <option value="PL">Polonia</option>
          <option value="PT">Portugal</option>
          <option value="PR">Puerto Rico</option>
          <option value="QA">Qatar</option>
          <option value="UK">Reino Unido</option>
          <option value="CF">República Centroafricana</option>
          <option value="CZ">República Checa</option>
          <option value="ZA">República de Sudáfrica</option>
          <option value="DO">República Dominicana</option>
          <option value="SK">República Eslovaca</option>
          <option value="RE">Reunión</option>
          <option value="RW">Ruanda</option>
          <option value="RO">Rumania</option>
          <option value="RU">Rusia</option>
          <option value="EH">Sahara Occidental</option>
          <option value="KN">Saint Kitts y Nevis</option>
          <option value="WS">Samoa</option>
          <option value="AS">Samoa Americana</option>
          <option value="SM">San Marino</option>
          <option value="VC">San Vicente y Granadinas</option>
          <option value="SH">Santa Helena</option>
          <option value="LC">Santa Lucía</option>
          <option value="ST">Santo Tomé y Príncipe</option>
          <option value="SN">Senegal</option>
          <option value="SC">Seychelles</option>
          <option value="SL">Sierra Leona</option>
          <option value="SG">Singapur</option>
          <option value="SY">Siria</option>
          <option value="SO">Somalia</option>
          <option value="LK">Sri Lanka</option>
          <option value="PM">St Pierre y Miquelon</option>
          <option value="SZ">Suazilandia</option>
          <option value="SD">Sudán</option>
          <option value="SE">Suecia</option>
          <option value="CH">Suiza</option>
          <option value="SR">Surinam</option>
          <option value="TH">Tailandia</option>
          <option value="TW">Taiwán</option>
          <option value="TZ">Tanzania</option>
          <option value="TJ">Tayikistán</option>
          <option value="TF">Territorios franceses del Sur</option>
          <option value="TP">Timor Oriental</option>
          <option value="TG">Togo</option>
          <option value="TO">Tonga</option>
          <option value="TT">Trinidad y Tobago</option>
          <option value="TN">Túnez</option>
          <option value="TM">Turkmenistán</option>
          <option value="TR">Turquía</option>
          <option value="TV">Tuvalu</option>
          <option value="UA">Ucrania</option>
          <option value="UG">Uganda</option>
          <option value="UY">Uruguay</option>
          <option value="UZ">Uzbekistán</option>
          <option value="VU">Vanuatu</option>
          <option value="VE">Venezuela</option>
          <option value="VN">Vietnam</option>
          <option value="YE">Yemen</option>
          <option value="YU">Yugoslavia</option>
          <option value="ZM">Zambia</option>
          <option value="ZW">Zimbabue</option>
        </select>
        <span class="form__content__label-errors" v-if="v$.countryOfOrigin.$error">
          {{ v$.countryOfOrigin.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Nacionalidad</label>
        <select v-model="v$.nationality.$model">
          <option value="AF">Afganistán</option>
          <option value="AL">Albania</option>
          <option value="DE">Alemania</option>
          <option value="AD">Andorra</option>
          <option value="AO">Angola</option>
          <option value="AI">Anguilla</option>
          <option value="AQ">Antártida</option>
          <option value="AG">Antigua y Barbuda</option>
          <option value="AN">Antillas Holandesas</option>
          <option value="SA">Arabia Saudí</option>
          <option value="DZ">Argelia</option>
          <option value="AR">Argentina</option>
          <option value="AM">Armenia</option>
          <option value="AW">Aruba</option>
          <option value="AU">Australia</option>
          <option value="AT">Austria</option>
          <option value="AZ">Azerbaiyán</option>
          <option value="BS">Bahamas</option>
          <option value="BH">Bahrein</option>
          <option value="BD">Bangladesh</option>
          <option value="BB">Barbados</option>
          <option value="BE">Bélgica</option>
          <option value="BZ">Belice</option>
          <option value="BJ">Benin</option>
          <option value="BM">Bermudas</option>
          <option value="BY">Bielorrusia</option>
          <option value="MM">Birmania</option>
          <option value="BO">Bolivia</option>
          <option value="BA">Bosnia y Herzegovina</option>
          <option value="BW">Botswana</option>
          <option value="BR">Brasil</option>
          <option value="BN">Brunei</option>
          <option value="BG">Bulgaria</option>
          <option value="BF">Burkina Faso</option>
          <option value="BI">Burundi</option>
          <option value="BT">Bután</option>
          <option value="CV">Cabo Verde</option>
          <option value="KH">Camboya</option>
          <option value="CM">Camerún</option>
          <option value="CA">Canadá</option>
          <option value="TD">Chad</option>
          <option value="CL">Chile</option>
          <option value="CN">China</option>
          <option value="CY">Chipre</option>
          <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
          <option value="CO">Colombia</option>
          <option value="KM">Comores</option>
          <option value="CG">Congo</option>
          <option value="CD">Congo, República Democrática del</option>
          <option value="KR">Corea</option>
          <option value="KP">Corea del Norte</option>
          <option value="CI">Costa de Marfíl</option>
          <option value="CR">Costa Rica</option>
          <option value="HR">Croacia (Hrvatska)</option>
          <option value="CU">Cuba</option>
          <option value="DK">Dinamarca</option>
          <option value="DJ">Djibouti</option>
          <option value="DM">Dominica</option>
          <option value="EC">Ecuador</option>
          <option value="EG">Egipto</option>
          <option value="SV">El Salvador</option>
          <option value="AE">Emiratos Árabes Unidos</option>
          <option value="ER">Eritrea</option>
          <option value="SI">Eslovenia</option>
          <option value="ES">España</option>
          <option value="US">Estados Unidos</option>
          <option value="EE">Estonia</option>
          <option value="ET">Etiopía</option>
          <option value="FJ">Fiji</option>
          <option value="PH">Filipinas</option>
          <option value="FI">Finlandia</option>
          <option value="FR">Francia</option>
          <option value="GA">Gabón</option>
          <option value="GM">Gambia</option>
          <option value="GE">Georgia</option>
          <option value="GH">Ghana</option>
          <option value="GI">Gibraltar</option>
          <option value="GD">Granada</option>
          <option value="GR">Grecia</option>
          <option value="GL">Groenlandia</option>
          <option value="GP">Guadalupe</option>
          <option value="GU">Guam</option>
          <option value="GT">Guatemala</option>
          <option value="GY">Guayana</option>
          <option value="GF">Guayana Francesa</option>
          <option value="GN">Guinea</option>
          <option value="GQ">Guinea Ecuatorial</option>
          <option value="GW">Guinea-Bissau</option>
          <option value="HT">Haití</option>
          <option value="HN">Honduras</option>
          <option value="HU">Hungría</option>
          <option value="IN">India</option>
          <option value="ID">Indonesia</option>
          <option value="IQ">Irak</option>
          <option value="IR">Irán</option>
          <option value="IE">Irlanda</option>
          <option value="BV">Isla Bouvet</option>
          <option value="CX">Isla de Christmas</option>
          <option value="IS">Islandia</option>
          <option value="KY">Islas Caimán</option>
          <option value="CK">Islas Cook</option>
          <option value="CC">Islas de Cocos o Keeling</option>
          <option value="FO">Islas Faroe</option>
          <option value="HM">Islas Heard y McDonald</option>
          <option value="FK">Islas Malvinas</option>
          <option value="MP">Islas Marianas del Norte</option>
          <option value="MH">Islas Marshall</option>
          <option value="UM">Islas menores de Estados Unidos</option>
          <option value="PW">Islas Palau</option>
          <option value="SB">Islas Salomón</option>
          <option value="SJ">Islas Svalbard y Jan Mayen</option>
          <option value="TK">Islas Tokelau</option>
          <option value="TC">Islas Turks y Caicos</option>
          <option value="VI">Islas Vírgenes (EEUU)</option>
          <option value="VG">Islas Vírgenes (Reino Unido)</option>
          <option value="WF">Islas Wallis y Futuna</option>
          <option value="IL">Israel</option>
          <option value="IT">Italia</option>
          <option value="JM">Jamaica</option>
          <option value="JP">Japón</option>
          <option value="JO">Jordania</option>
          <option value="KZ">Kazajistán</option>
          <option value="KE">Kenia</option>
          <option value="KG">Kirguizistán</option>
          <option value="KI">Kiribati</option>
          <option value="KW">Kuwait</option>
          <option value="LA">Laos</option>
          <option value="LS">Lesotho</option>
          <option value="LV">Letonia</option>
          <option value="LB">Líbano</option>
          <option value="LR">Liberia</option>
          <option value="LY">Libia</option>
          <option value="LI">Liechtenstein</option>
          <option value="LT">Lituania</option>
          <option value="LU">Luxemburgo</option>
          <option value="MK">Macedonia, Ex-República Yugoslava de</option>
          <option value="MG">Madagascar</option>
          <option value="MY">Malasia</option>
          <option value="MW">Malawi</option>
          <option value="MV">Maldivas</option>
          <option value="ML">Malí</option>
          <option value="MT">Malta</option>
          <option value="MA">Marruecos</option>
          <option value="MQ">Martinica</option>
          <option value="MU">Mauricio</option>
          <option value="MR">Mauritania</option>
          <option value="YT">Mayotte</option>
          <option value="MX" selected>México</option>
          <option value="FM">Micronesia</option>
          <option value="MD">Moldavia</option>
          <option value="MC">Mónaco</option>
          <option value="MN">Mongolia</option>
          <option value="MS">Montserrat</option>
          <option value="MZ">Mozambique</option>
          <option value="NA">Namibia</option>
          <option value="NR">Nauru</option>
          <option value="NP">Nepal</option>
          <option value="NI">Nicaragua</option>
          <option value="NE">Níger</option>
          <option value="NG">Nigeria</option>
          <option value="NU">Niue</option>
          <option value="NF">Norfolk</option>
          <option value="NO">Noruega</option>
          <option value="NC">Nueva Caledonia</option>
          <option value="NZ">Nueva Zelanda</option>
          <option value="OM">Omán</option>
          <option value="NL">Países Bajos</option>
          <option value="PA">Panamá</option>
          <option value="PG">Papúa Nueva Guinea</option>
          <option value="PK">Paquistán</option>
          <option value="PY">Paraguay</option>
          <option value="PE">Perú</option>
          <option value="PN">Pitcairn</option>
          <option value="PF">Polinesia Francesa</option>
          <option value="PL">Polonia</option>
          <option value="PT">Portugal</option>
          <option value="PR">Puerto Rico</option>
          <option value="QA">Qatar</option>
          <option value="UK">Reino Unido</option>
          <option value="CF">República Centroafricana</option>
          <option value="CZ">República Checa</option>
          <option value="ZA">República de Sudáfrica</option>
          <option value="DO">República Dominicana</option>
          <option value="SK">República Eslovaca</option>
          <option value="RE">Reunión</option>
          <option value="RW">Ruanda</option>
          <option value="RO">Rumania</option>
          <option value="RU">Rusia</option>
          <option value="EH">Sahara Occidental</option>
          <option value="KN">Saint Kitts y Nevis</option>
          <option value="WS">Samoa</option>
          <option value="AS">Samoa Americana</option>
          <option value="SM">San Marino</option>
          <option value="VC">San Vicente y Granadinas</option>
          <option value="SH">Santa Helena</option>
          <option value="LC">Santa Lucía</option>
          <option value="ST">Santo Tomé y Príncipe</option>
          <option value="SN">Senegal</option>
          <option value="SC">Seychelles</option>
          <option value="SL">Sierra Leona</option>
          <option value="SG">Singapur</option>
          <option value="SY">Siria</option>
          <option value="SO">Somalia</option>
          <option value="LK">Sri Lanka</option>
          <option value="PM">St Pierre y Miquelon</option>
          <option value="SZ">Suazilandia</option>
          <option value="SD">Sudán</option>
          <option value="SE">Suecia</option>
          <option value="CH">Suiza</option>
          <option value="SR">Surinam</option>
          <option value="TH">Tailandia</option>
          <option value="TW">Taiwán</option>
          <option value="TZ">Tanzania</option>
          <option value="TJ">Tayikistán</option>
          <option value="TF">Territorios franceses del Sur</option>
          <option value="TP">Timor Oriental</option>
          <option value="TG">Togo</option>
          <option value="TO">Tonga</option>
          <option value="TT">Trinidad y Tobago</option>
          <option value="TN">Túnez</option>
          <option value="TM">Turkmenistán</option>
          <option value="TR">Turquía</option>
          <option value="TV">Tuvalu</option>
          <option value="UA">Ucrania</option>
          <option value="UG">Uganda</option>
          <option value="UY">Uruguay</option>
          <option value="UZ">Uzbekistán</option>
          <option value="VU">Vanuatu</option>
          <option value="VE">Venezuela</option>
          <option value="VN">Vietnam</option>
          <option value="YE">Yemen</option>
          <option value="YU">Yugoslavia</option>
          <option value="ZM">Zambia</option>
          <option value="ZW">Zimbabue</option>
        </select>
        <span class="form__content__label-errors" v-if="v$.nationality.$error">
          {{ v$.nationality.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Calidad Migratoria</label>
        <select v-model="v$.immigrationStatus.$model">
          <option value="" selected>Seleccione...</option>
          <option value="NACIONAL">Nacional</option>
          <option value="EXTRANJERO">Extranjero</option>
        </select>
        <span class="form__content__label-errors" v-if="v$.immigrationStatus.$error">
          {{ v$.immigrationStatus.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Jurisdicción para efectos fiscales</label>
        <select v-model="v$.taxCountry.$model">
          <option value="AF">Afganistán</option>
          <option value="AL">Albania</option>
          <option value="DE">Alemania</option>
          <option value="AD">Andorra</option>
          <option value="AO">Angola</option>
          <option value="AI">Anguilla</option>
          <option value="AQ">Antártida</option>
          <option value="AG">Antigua y Barbuda</option>
          <option value="AN">Antillas Holandesas</option>
          <option value="SA">Arabia Saudí</option>
          <option value="DZ">Argelia</option>
          <option value="AR">Argentina</option>
          <option value="AM">Armenia</option>
          <option value="AW">Aruba</option>
          <option value="AU">Australia</option>
          <option value="AT">Austria</option>
          <option value="AZ">Azerbaiyán</option>
          <option value="BS">Bahamas</option>
          <option value="BH">Bahrein</option>
          <option value="BD">Bangladesh</option>
          <option value="BB">Barbados</option>
          <option value="BE">Bélgica</option>
          <option value="BZ">Belice</option>
          <option value="BJ">Benin</option>
          <option value="BM">Bermudas</option>
          <option value="BY">Bielorrusia</option>
          <option value="MM">Birmania</option>
          <option value="BO">Bolivia</option>
          <option value="BA">Bosnia y Herzegovina</option>
          <option value="BW">Botswana</option>
          <option value="BR">Brasil</option>
          <option value="BN">Brunei</option>
          <option value="BG">Bulgaria</option>
          <option value="BF">Burkina Faso</option>
          <option value="BI">Burundi</option>
          <option value="BT">Bután</option>
          <option value="CV">Cabo Verde</option>
          <option value="KH">Camboya</option>
          <option value="CM">Camerún</option>
          <option value="CA">Canadá</option>
          <option value="TD">Chad</option>
          <option value="CL">Chile</option>
          <option value="CN">China</option>
          <option value="CY">Chipre</option>
          <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
          <option value="CO">Colombia</option>
          <option value="KM">Comores</option>
          <option value="CG">Congo</option>
          <option value="CD">Congo, República Democrática del</option>
          <option value="KR">Corea</option>
          <option value="KP">Corea del Norte</option>
          <option value="CI">Costa de Marfíl</option>
          <option value="CR">Costa Rica</option>
          <option value="HR">Croacia (Hrvatska)</option>
          <option value="CU">Cuba</option>
          <option value="DK">Dinamarca</option>
          <option value="DJ">Djibouti</option>
          <option value="DM">Dominica</option>
          <option value="EC">Ecuador</option>
          <option value="EG">Egipto</option>
          <option value="SV">El Salvador</option>
          <option value="AE">Emiratos Árabes Unidos</option>
          <option value="ER">Eritrea</option>
          <option value="SI">Eslovenia</option>
          <option value="ES">España</option>
          <option value="US">Estados Unidos</option>
          <option value="EE">Estonia</option>
          <option value="ET">Etiopía</option>
          <option value="FJ">Fiji</option>
          <option value="PH">Filipinas</option>
          <option value="FI">Finlandia</option>
          <option value="FR">Francia</option>
          <option value="GA">Gabón</option>
          <option value="GM">Gambia</option>
          <option value="GE">Georgia</option>
          <option value="GH">Ghana</option>
          <option value="GI">Gibraltar</option>
          <option value="GD">Granada</option>
          <option value="GR">Grecia</option>
          <option value="GL">Groenlandia</option>
          <option value="GP">Guadalupe</option>
          <option value="GU">Guam</option>
          <option value="GT">Guatemala</option>
          <option value="GY">Guayana</option>
          <option value="GF">Guayana Francesa</option>
          <option value="GN">Guinea</option>
          <option value="GQ">Guinea Ecuatorial</option>
          <option value="GW">Guinea-Bissau</option>
          <option value="HT">Haití</option>
          <option value="HN">Honduras</option>
          <option value="HU">Hungría</option>
          <option value="IN">India</option>
          <option value="ID">Indonesia</option>
          <option value="IQ">Irak</option>
          <option value="IR">Irán</option>
          <option value="IE">Irlanda</option>
          <option value="BV">Isla Bouvet</option>
          <option value="CX">Isla de Christmas</option>
          <option value="IS">Islandia</option>
          <option value="KY">Islas Caimán</option>
          <option value="CK">Islas Cook</option>
          <option value="CC">Islas de Cocos o Keeling</option>
          <option value="FO">Islas Faroe</option>
          <option value="HM">Islas Heard y McDonald</option>
          <option value="FK">Islas Malvinas</option>
          <option value="MP">Islas Marianas del Norte</option>
          <option value="MH">Islas Marshall</option>
          <option value="UM">Islas menores de Estados Unidos</option>
          <option value="PW">Islas Palau</option>
          <option value="SB">Islas Salomón</option>
          <option value="SJ">Islas Svalbard y Jan Mayen</option>
          <option value="TK">Islas Tokelau</option>
          <option value="TC">Islas Turks y Caicos</option>
          <option value="VI">Islas Vírgenes (EEUU)</option>
          <option value="VG">Islas Vírgenes (Reino Unido)</option>
          <option value="WF">Islas Wallis y Futuna</option>
          <option value="IL">Israel</option>
          <option value="IT">Italia</option>
          <option value="JM">Jamaica</option>
          <option value="JP">Japón</option>
          <option value="JO">Jordania</option>
          <option value="KZ">Kazajistán</option>
          <option value="KE">Kenia</option>
          <option value="KG">Kirguizistán</option>
          <option value="KI">Kiribati</option>
          <option value="KW">Kuwait</option>
          <option value="LA">Laos</option>
          <option value="LS">Lesotho</option>
          <option value="LV">Letonia</option>
          <option value="LB">Líbano</option>
          <option value="LR">Liberia</option>
          <option value="LY">Libia</option>
          <option value="LI">Liechtenstein</option>
          <option value="LT">Lituania</option>
          <option value="LU">Luxemburgo</option>
          <option value="MK">Macedonia, Ex-República Yugoslava de</option>
          <option value="MG">Madagascar</option>
          <option value="MY">Malasia</option>
          <option value="MW">Malawi</option>
          <option value="MV">Maldivas</option>
          <option value="ML">Malí</option>
          <option value="MT">Malta</option>
          <option value="MA">Marruecos</option>
          <option value="MQ">Martinica</option>
          <option value="MU">Mauricio</option>
          <option value="MR">Mauritania</option>
          <option value="YT">Mayotte</option>
          <option value="MX" selected>México</option>
          <option value="FM">Micronesia</option>
          <option value="MD">Moldavia</option>
          <option value="MC">Mónaco</option>
          <option value="MN">Mongolia</option>
          <option value="MS">Montserrat</option>
          <option value="MZ">Mozambique</option>
          <option value="NA">Namibia</option>
          <option value="NR">Nauru</option>
          <option value="NP">Nepal</option>
          <option value="NI">Nicaragua</option>
          <option value="NE">Níger</option>
          <option value="NG">Nigeria</option>
          <option value="NU">Niue</option>
          <option value="NF">Norfolk</option>
          <option value="NO">Noruega</option>
          <option value="NC">Nueva Caledonia</option>
          <option value="NZ">Nueva Zelanda</option>
          <option value="OM">Omán</option>
          <option value="NL">Países Bajos</option>
          <option value="PA">Panamá</option>
          <option value="PG">Papúa Nueva Guinea</option>
          <option value="PK">Paquistán</option>
          <option value="PY">Paraguay</option>
          <option value="PE">Perú</option>
          <option value="PN">Pitcairn</option>
          <option value="PF">Polinesia Francesa</option>
          <option value="PL">Polonia</option>
          <option value="PT">Portugal</option>
          <option value="PR">Puerto Rico</option>
          <option value="QA">Qatar</option>
          <option value="UK">Reino Unido</option>
          <option value="CF">República Centroafricana</option>
          <option value="CZ">República Checa</option>
          <option value="ZA">República de Sudáfrica</option>
          <option value="DO">República Dominicana</option>
          <option value="SK">República Eslovaca</option>
          <option value="RE">Reunión</option>
          <option value="RW">Ruanda</option>
          <option value="RO">Rumania</option>
          <option value="RU">Rusia</option>
          <option value="EH">Sahara Occidental</option>
          <option value="KN">Saint Kitts y Nevis</option>
          <option value="WS">Samoa</option>
          <option value="AS">Samoa Americana</option>
          <option value="SM">San Marino</option>
          <option value="VC">San Vicente y Granadinas</option>
          <option value="SH">Santa Helena</option>
          <option value="LC">Santa Lucía</option>
          <option value="ST">Santo Tomé y Príncipe</option>
          <option value="SN">Senegal</option>
          <option value="SC">Seychelles</option>
          <option value="SL">Sierra Leona</option>
          <option value="SG">Singapur</option>
          <option value="SY">Siria</option>
          <option value="SO">Somalia</option>
          <option value="LK">Sri Lanka</option>
          <option value="PM">St Pierre y Miquelon</option>
          <option value="SZ">Suazilandia</option>
          <option value="SD">Sudán</option>
          <option value="SE">Suecia</option>
          <option value="CH">Suiza</option>
          <option value="SR">Surinam</option>
          <option value="TH">Tailandia</option>
          <option value="TW">Taiwán</option>
          <option value="TZ">Tanzania</option>
          <option value="TJ">Tayikistán</option>
          <option value="TF">Territorios franceses del Sur</option>
          <option value="TP">Timor Oriental</option>
          <option value="TG">Togo</option>
          <option value="TO">Tonga</option>
          <option value="TT">Trinidad y Tobago</option>
          <option value="TN">Túnez</option>
          <option value="TM">Turkmenistán</option>
          <option value="TR">Turquía</option>
          <option value="TV">Tuvalu</option>
          <option value="UA">Ucrania</option>
          <option value="UG">Uganda</option>
          <option value="UY">Uruguay</option>
          <option value="UZ">Uzbekistán</option>
          <option value="VU">Vanuatu</option>
          <option value="VE">Venezuela</option>
          <option value="VN">Vietnam</option>
          <option value="YE">Yemen</option>
          <option value="YU">Yugoslavia</option>
          <option value="ZM">Zambia</option>
          <option value="ZW">Zimbabue</option>
        </select>
        <span class="form__content__label-errors" v-if="v$.taxCountry.$error">
          {{ v$.taxCountry.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <hr>
      <h3>Domicilio</h3>
      <div class="form__content__label">
        <label>Calle</label>
        <input type="text" v-model="v$.address.street.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.street.$error">
          {{ v$.address.street.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>

      <div class="form__content__label">
        <label>Número</label>
        <input type="text" v-model="v$.address.num.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.num.$error">
          {{ v$.address.num.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>

      <div class="form__content__label">
        <label>Número Interior</label>
        <input type="text" v-model="v$.address.numint.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.numint.$error">
          {{ v$.address.numint.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Colonia</label>
        <input type="text" v-model="v$.address.district.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.district.$error">
          {{ v$.address.district.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>C.P.</label>
        <input type="text" v-model="v$.address.cp.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.cp.$error">
          {{ v$.address.cp.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Ciudad</label>
        <input type="text" v-model="v$.address.city.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.city.$error">
          {{ v$.address.city.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Estado</label>
        <input type="text" v-model="v$.address.state.$model" autocomplete="off" />
        <span class="form__content__label-errors" v-if="v$.address.state.$error">
          {{ v$.address.state.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="form__content__label">
        <label>Comprobante de Domicilio</label>
        <input type="file" @change="proofOfAddressChange" />
        <span class="form__content__label-errors" v-if="v$.proofOfAddress.$error">
          {{ v$.proofOfAddress.$errors.map(({ $message }) => $message).join(', ') }}
        </span>
      </div>
      <div class="">
        <input id="isFiscalResidence" type="checkbox" v-model="v$.address.isFiscalResidence.$model"
          @change="fresidence.isFiscalResidence = true" />
        <label for="isFiscalResidence">Usar como domicilio Fiscal</label>
      </div>
      <div class="domicilio-fiscal" v-if="!v$.address.isFiscalResidence.$model">
        <hr>
        <h3>Domicilio Fiscal</h3>
        <div class="form__content__label">
          <label>Calle</label>
          <input v-model="fr$.street.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.street.$error">
            {{ fr$.street.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Número</label>
          <input v-model="fr$.num.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.num.$error">
            {{ fr$.num.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Número Interior</label>
          <input v-model="fr$.numint.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.numint.$error">
            {{ fr$.numint.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Colonia</label>
          <input v-model="fr$.district.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.district.$error">
            {{ fr$.district.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>C.P.</label>
          <input v-model="fr$.cp.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.cp.$error">
            {{ fr$.cp.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Ciudad</label>
          <input v-model="fr$.city.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.city.$error">
            {{ fr$.city.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Estado</label>
          <input v-model="fr$.state.$model" type="text" autocomplete="off" />
          <span class="form__content__label-errors" v-if="fr$.state.$error">
            {{ fr$.state.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Comprobante de Domicilio</label>
          <input type="file" @change="proofOfAddressChange2" />
          <span class="form__content__label-errors" v-if="v$.proofOfAddress1.$error">
            {{ v$.proofOfAddress1.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
      </div>
      <hr>
      <button type="button" @click="tabSelected = 'inversion'" :disabled="v$.$invalid">Continuar</button>
    </div>
  </div>

  <div class="form" v-if="tabSelected === 'inversion' && !loading">
    <div class="form__content">
      <div class="alert" v-if="errors.length > 0">
        <ul>
          <li v-for="(error, $index) in errors" :key="$index">{{ error }}</li>
        </ul>
      </div>
      <div class="form__content__label">
        <label>¿Cuanto quieres invertir?</label>
        <InversionPicker v-model="profile.amount" />
      </div>
      <div class="form__content__label">
        <label>¿En cuantos meses quieres dividir tu inversión?</label>
        <select v-model="profile.payments">
          <option :value="null">1 solo pago</option>
          <option :value="{ payments: 9 }">9 MSI</option>
          <option :value="{ payments: 12 }">12 MSI</option>
          <option :value="{ payments: 18 }">18 MSI</option>
        </select>
      </div>
      <div class="form__content__label">
        <label>Estructura de la Inversión: </label>
        <input readonly v-model="profile.partialAmount" />
      </div>
      <div class="form__content__label">
        <label>Porcentaje de DocInWay S.A.P.I de C.V. a adquirir: </label>
        <input type="text" readonly v-model="profile.percentage" />
      </div>
      <div class="form__content__label">
        <label>Acepto los
          <a href="https://docinway.com.mx/public/terminosycondiciones.pdf" target="_blank" rel="noopener noreferrer">
            terminos y condiciones
          </a>
          /
          <a href="https://docinway.com.mx/public/avisodeprivacidad.pdf" target="_blank" rel="noopener noreferrer">
            Aviso de privacidad
          </a>
        </label>
        <input type="checkbox" v-model="terms" />
      </div>
      <span class="form__content__label-errors" v-if="!terms">
        Necesitas aceptar los terminos y condiciones
      </span>
      <button type="button" class="margin-bottom-2" @click="confirmed = true"
        :disabled="!profile.amount || !terms">Confirmar Monto de Inversión</button>
      <div class="clearfix"></div>
      <template v-if="confirmed">
        <!-- <template v-if="true"> -->
        <hr>
        <h3>Necesitaremos una identificación oficial <small>(INE, Pasaporte, DNI)</small></h3>
        <div class="form__content__label">
          <label>Tipo de Identificación</label>
          <select v-model="v2$.identificationType.$model">
            <option value="ine">INE</option>
            <option value="pasaporte">Pasaporte</option>
          </select>
          <span class="form__content__label-errors" v-if="v2$.identificationType.$error">
            {{ v2$.identificationType.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>No. Folio</label>
          <input type="text" v-model="v2$.folio.$model" />
          <span class="form__content__label-errors" v-if="v2$.folio.$error">
            {{ v2$.folio.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Autoridad que Emite</label>
          <input type="text" v-model="v2$.issuingAuthority.$model" />
          <span class="form__content__label-errors" v-if="v2$.issuingAuthority.$error">
            {{ v2$.issuingAuthority.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Foto parte frontal</label>
          <input type="file" @change="photoFrontSideChange" />
          <span class="form__content__label-errors" v-if="v2$.photoFrontSide.$error">
            {{ v2$.photoFrontSide.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Foto parte posterior</label>
          <input type="file" @change="photoBackSideChange" />
          <span class="form__content__label-errors" v-if="v2$.photoBackSide.$error">
            {{ v2$.photoBackSide.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Clave de RFC o Número de Identificación Fiscal</label>
          <input type="text" v-model="v2$.fiscalId.$model" />
          <span class="form__content__label-errors" v-if="v2$.fiscalId.$error">
            {{ v2$.fiscalId.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label>Cédula RFC</label>
          <input type="file" @change="rfcNumberChange" />
          <span class="form__content__label-errors" v-if="v2$.rfcNumber.$error">
            {{ v2$.rfcNumber.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <hr>
        <div class="form__content__label">
          <label>Nombre de Tarjetahabiente</label>
          <input type="text" v-model="card.name" autocomplete="off" />
        </div>
        <div class="form__content__label">
          <label>Número de Tarjeta</label>
          <input type="text" v-model="card.number" autocomplete="off" />
        </div>
        <div class="form__content__label">
          <label>Fecha de Vencimiento</label>
          <select v-model="card.month">
            <option :value="null">Mes</option>
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <select v-model="card.year">
            <option :value="null">Año</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
          </select>
        </div>
        <div class="form__content__label">
          <label>Código de Seguridad</label>
          <input type="password" v-model="card.cvv" autocomplete="new-password" />
        </div>
        <button type="button" @click="suscribe">Continuar</button>
        <button class="margin-rigth-1" type="button" @click="tabSelected = 'datos'">Atras</button>
        <div class="clearfix"></div>
      </template>
    </div>
  </div>

  <div class="form form-certificado" v-if="tabSelected === 'certificado' && !loading">
    <div class="text-center">
      <h2>¡Felicidades, ya eres Socio!</h2>
      <div>
        <img src="../assets/logo_vertical.png" alt="Logo" width="300">
      </div>
      <div class="form__content__label" v-if="newInvestment.authorization">
        <label>Folio de autorizacion: </label>
        <b>{{ newInvestment.authorization }}</b>
      </div>
      <div class="form__content__label" v-if="newInvestment.orderId">
        <label>Numero de certificado: </label>
        <b>{{ newInvestment.orderId }}</b>
      </div>
      <p>Tu certificado llegará por correo electrónico</p>
      <router-link :to="{ name: 'Home' }" class="btn-lg actions__button">Regresar al inicio</router-link>
    </div>
  </div>

  <div class="form form-loader" v-if="loading">
    <div class="text-center">
      <h2>Estamos procesando tu petición no cierres la pestaña...</h2>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/scss/views/form.scss";
</style>
