import apiAuth from './api/apiAuth';

class UserService {
  static async update(data) {
    const res = await apiAuth.post('crowdfunding/partner/update', data);
    return res.data;
  }

  static async getInvestments() {
    const res = await apiAuth.get('crowdfunding');
    return res.data;
  }
}

export default UserService;
