import axios from 'axios';
import { API_URL } from '../../config';
import SessionService from '../SessionService';

const headers = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  // 'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Credentials': true,
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const configg = { ...config };
    const token = SessionService.getAccessToken();
    if (token) {
      configg.headers.Authorization = `Bearer ${token}`;
    }
    return configg;
  },
  (error) => Promise.reject(error),
);

/* axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post("/auth/refreshtoken", {
            refreshToken: SessionService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;

          astore.dispatch('auth/refreshToken', accessToken);
          SessionService.updateLocalAccessToken(accessToken);

          return axiosInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
); */

export default axiosInstance;
